.react-datepicker__day--selected {
  /* width: 15px;
  height: 15px; */
  background: #6bba21 !important;
  border-radius: 50%;
}

.react-datepicker__day--selected:hover {
  background: #8cb962 !important;
  border-radius: 50%;
}
