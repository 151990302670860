.selectMulti .css-1s2u09g-control, .css-1pahdxg-control{
    width: 100%;
    align-items: center;
    outline: 2px solid transparent !important;
    outline-offset: 2px;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    color: var(--chakra-colors-blue-300);
    font-weight: var(--chakra-fontWeights-normal);
    font-size: var(--chakra-fontSizes-lg);
    border-color: var(--chakra-colors-blue-300) !important;
    border-radius: var(--chakra-radii-3xl) !important;
    border-width: 2px !important;
    background-color: var(--chakra-colors-white) !important;
    cursor: pointer;
    min-height: var(--chakra-sizes-10);
    margin-right: var(--chakra-space-8);
    min-width: var(--chakra-sizes-40);
    box-shadow: none !important;
}
.selectMulti .css-1rhbuit-multiValue{
    background-color: #FFF;
    border: 1px solid #A4B7CB;
    border-radius: 15px;
    padding: 1px 5px;
}
.selectMulti .css-xb97g8:hover{
    background-color: transparent;
    color: #6BBA05;
}
.selectMulti .css-tlfecz-indicatorContainer, .selectMulti .css-tlfecz-indicatorContainer:hover{
    color: #6BBA05;
}
.selectMulti .css-1gtu0rj-indicatorContainer, .selectMulti .css-1gtu0rj-indicatorContainer:hover{
    color: #6BBA05;
    transform: rotate(180deg);
}
.selectMulti .css-1okebmr-indicatorSeparator{
    background-color: transparent;
}
.selectMulti .css-14el2xx-placeholder{
    color: #FFF;
}
.selectMulti.error .css-1s2u09g-control{
    border-color: red !important;
}